import { render, staticRenderFns } from "./ViolationsTable.vue?vue&type=template&id=e40b563a"
import script from "./ViolationsTable.vue?vue&type=script&lang=js"
export * from "./ViolationsTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports