<template>
    <div class="home-form py-7">
        <div class="home-form__inner home-container">
            <h1 class="title is-3 has-text-weight-bold has-text-centered">Set Up Multi-Factor Authentication</h1>
            <mfa-setup @mfa-success="handleMfaSuccess" @mfa-error="handleMfaError" />
        </div>
    </div>
</template>

<script>
import MfaSetup from '@/components/widgets/MfaSetup';

export default {
    components: {
        MfaSetup,
    },

    methods: {
        handleMfaSuccess() {
            this.$router.replace('/dashboard/health-and-safety');
        },
    },
};
</script>
