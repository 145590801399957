<template>
    <v-group ref="group">
        <v-line :config="proximityLineConfig" />
        <v-rect :config="proximityBoxConfig" />
        <v-text :config="proximityTextConfig" />
    </v-group>
</template>

<script>
import { COLORS } from '@/utils/constants';
import { convertToCanvasLine } from '@/utils/canvas';

export default {
    props: {
        result: {
            type: Object,
            required: true,
        },
        scale: {
            type: Number,
            required: true,
        },
    },

    computed: {
        proximityLineConfig() {
            return {
                points: this.centerPoints(this.result),
                stroke: this.proximityLineColor,
                strokeWidth: 2,
                opacity: this.result.opacity !== undefined ? this.result.opacity : 0.8,
            };
        },

        proximityTextConfig() {
            const { x, y } = this.getMidPoint();
            return {
                text: this.distanceText,
                x,
                y,
                offsetY: 10,
                fontSize: 16,
                fill: COLORS.black,
                strokeEnabled: true,
            };
        },

        proximityBoxConfig() {
            const { x, y } = this.getMidPoint();
            return {
                x,
                y,
                width: this.distanceText.length * 10,
                height: 15,
                offsetY: 11,
                offsetX: 2,
                fill: COLORS.white,
                opacity: this.result.opacity !== undefined ? this.result.opacity : 0.8,
            };
        },

        proximityLineColor() {
            if (this.result.risk_level === 'danger') {
                return COLORS.red;
            }

            if (this.result.risk_level === 'caution') {
                return COLORS.yellow;
            }
            return COLORS.green;
        },

        distanceText() {
            return this.result.distance.toFixed(2).toString();
        },
    },

    methods: {

        centerPoints(result) {
            const { box1, box2 } = result;
            const center1 = {
                x: (box1.x1 + box1.x2) / 2,
                y: (box1.y1 + box1.y2) / 2,
            };
            const center2 = {
                x: (box2.x1 + box2.x2) / 2,
                y: (box2.y1 + box2.y2) / 2,
            };
            const canvasLine = convertToCanvasLine({
                str: [center1.x, center1.y, center2.x, center2.y],
                scale: this.scale,
                translate: this.translate,
            });
            return canvasLine;
        },

        getMidPoint() {
            const points = this.centerPoints(this.result);
            const midPoint = {
                x: (points[0] + points[2]) / 2,
                y: (points[1] + points[3]) / 2,
            };
            return midPoint;
        },
    },
};
</script>
