import axios from 'axios';
import { get } from 'lodash';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import {
    buildStartEndDateUrl,
    buildUrlParams,
    createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { hsDetectionsTransformer } from '@/utils/transformers/hs/detections';

const state = getDefaultDataState({ hasPagination: true });

const getters = {
    ...getDefaultDataGetters({ hasPagination: true }),

    imageIds(state, getters) {
        return getters.formattedApiResults
            .map((row) => get(row, 'image.id'))
            .filter((r) => r);
    },

    formattedApiResults(state, getters) {
        return hsDetectionsTransformer(getters.apiResults);
    },

    apiUrlParams(state, getters, rootState, rootGetters) {
        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            plate: rootGetters['dataFilters/getSelectedPlates'],
            confidence: rootGetters['dataFilters/getConfidenceLevel'],
            risk: rootGetters['dataFilters/getRiskLevel'],
            perPage: state.perPage,
            page: state.currentPage,
            additional: {
                hs_type: 'zone',
            },
        });
        return params;
    },

    apiUrl(state, getters, rootState) {
        return buildStartEndDateUrl({
            path: 'hsafety/detections',
            startDate: rootState.dataFilters.startDate,
            endDate: rootState.dataFilters.endDate,
        });
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: true }),
};

const actions = {
    loadData({ state, commit, getters, dispatch }, { perPage = null, page = null }) {
        if (page) {
            commit('setPage', { page });
        }
        if (perPage) {
            commit('setPerPage', { perPage });
        }

        const params = getters.apiUrlParams;
        const path = getters.apiUrl;

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: 'loading' });
        return axios({
            url: getApiUrl({ path }),
            params,
        })
            .then((response) => {
                commit('setStatus', { status: 'success' });
                commit('setData', { data: response.data });
                commit('setDataHash', { dataHash });
            })
            .then(() => dispatch('media/images/preload', { ids: getters.imageIds }, { root: true }))
            .then(() => dispatch('dataExport/setCSVData', { data: getters.formattedApiResults, prefix: 'hs' }, { root: true }))
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: 'error' });
            });
    },

    exportData(
        { commit, getters, dispatch, rootState, rootGetters },
        { perPage = null, page = null },
    ) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            plate: rootGetters['dataFilters/getSelectedPlates'],
            confidence: rootGetters['dataFilters/getConfidenceLevel'],
            risk: rootGetters['dataFilters/getRiskLevel'],
            perPage,
            page,
            additional: {
                hs_type: 'zone',
            },
        });

        const path = buildStartEndDateUrl({
            path: 'hsafety/detections',
            startDate,
            endDate,
        });

        return axios({
            url: getApiUrl({ path }),
            params,
        })
            .then((response) => {
                commit('setData', { data: response.data });
                commit('dataExport/setTotalItemCount', { data: response.data?.count || 0 }, { root: true });
                dispatch(
                    'dataExport/setCSVData',
                    { data: getters.formattedApiResults, prefix: 'hs' },
                    { root: true },
                );
            })
            .then(() => commit('dataExport/setLoadingStatus', { status: false }, { root: true }))
            .catch((err) => {
                errorHandler(err);
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
