<template>
    <div class="home-form py-7">
        <div class="home-form__inner home-container">
            <h1 class="title is-3 has-text-weight-bold has-text-centered">Verification Code Required</h1>
            <mfa-input @mfa-success="handleMfaSuccess" @mfa-error="handleMfaError" />
        </div>
    </div>
</template>

<script>
import MfaInput from '@/components/widgets/MfaInput';

export default {
    components: {
        MfaInput,
    },

    methods: {
        handleMfaSuccess() {
            this.$router.replace('/dashboard/health-and-safety');
        },
    },
};
</script>
