<template>
    <div class="totals columns is-multiline">
        <div class="totals__col column is-6-tablet is-4-desktop">
            <be-widget icon="alert-outline" iconType="is-light" :is-loading="exclusionCount === null">
                <template v-slot:heading>Total exclusion zone detections</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ exclusionCount }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-4-desktop">
            <be-widget icon="vector-line" iconType="is-light" :is-loading="distanceCount === null">
                <template v-slot:heading>Close people/forklift interactions</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ distanceCount }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-4-desktop">
            <be-widget icon="speedometer" iconType="is-light" :is-loading="speedCount === null">
                <template v-slot:heading>Speed detections (>20km/h)</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ speedCount }}</p>
                </template>
            </be-widget>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { flatten } from 'lodash';
import { mapMutations } from 'vuex/dist/vuex.common.js';
import { formatNumber } from '@/utils/formatters/numbers';

export default {
    data() {
        return {
            exclusionCount: null,
            speedCount: null,
            distanceCount: null,
        };
    },

    watch: {
        getCountData: {
            handler(newValue) {
                if (newValue) {
                    this.updateCountData();
                }
            },
            immediate: false,
            deep: true,
        },

        getFilterHash() {
            this.refreshData();
        },
    },

    mounted() {
        this.refreshData();
    },

    computed: {
        ...mapGetters('hs/summary', ['getCountData']),
        ...mapGetters('dataFilters', ['getFilterHash']),
    },

    methods: {
        ...mapActions('hs/summary', ['loadData']),
        ...mapMutations('hs/summary', ['setCountData']),

        updateCountData() {
            const hasRiskType = (types) => this.getCountData.some(({ riskType }) => types.includes(riskType));

            const hasExclusionZone = hasRiskType(['ExclusionZone']);
            const hasDistanceZone = hasRiskType(['DistanceZone-Public', 'DistanceZone-Worker']);
            const hasOverSpeed = hasRiskType(['OverSpeed-GroupB', 'OverSpeed-GroupC']);

            this.exclusionCount = hasExclusionZone ? this.totalExclusionZoneDetections() : null;
            this.speedCount = hasOverSpeed ? this.totalSpeedDetections() : null;
            this.distanceCount = hasDistanceZone ? this.totalForkliftInteractions() : null;
        },

        refreshData() {
            ['exclusionCount', 'speedCount', 'distanceCount'].forEach((key) => {
                this[key] = null;
            });
            this.loadData();
        },

        totalExclusionZoneDetections() {
            const totalDetections = flatten(this.getCountData
                .filter((item) => item.riskType === 'ExclusionZone')
                .map((item) => item.data.map((dataItem) => dataItem.violation_count)))
                .reduce((a, b) => a + b, 0);
            return formatNumber({ number: totalDetections });
        },

        totalForkliftInteractions() {
            const totalDetections = flatten(this.getCountData
                .filter((item) => item.riskType === 'DistanceZone-Public'
                    || item.riskType === 'DistanceZone-Worker')
                .map((item) => item.data.map((dataItem) => dataItem.violation_count)))
                .reduce((a, b) => a + b, 0);
            return formatNumber({ number: totalDetections });
        },

        totalSpeedDetections() {
            const totalDetections = flatten(this.getCountData
                .filter((item) => item.riskType === 'OverSpeed-GroupB'
                    || item.riskType === 'OverSpeed-GroupC')
                .map((item) => item.data.map((dataItem) => dataItem.violation_count)))
                .reduce((a, b) => a + b, 0);
            return formatNumber({ number: totalDetections });
        },
    },
};
</script>

<style lang="scss" scoped>
.totals {
    justify-content: stretch;

    &__col {
        display: flex;
        justify-content: stretch;
    }
}
</style>
