<template>
    <form @submit.prevent="submit" novalidate>
        <p class="subtitle is-6 has-text-centered mt-3">
            Step 1 : Scan the QR code with your authenticator app<br />
            Step 2 : Enter the authentication code in the field below
        </p>
        <div style="display: flex; align-items: center; justify-content: center; margin: 20px 0">
            <img :src="`${mfaQRCode}`" style="max-width: 300px; background-color: white;" alt="MFA QR Code" />
        </div>
        <b-field
            :type="$v.mfaCode.$error ? 'is-danger' : ''"
            :message="$v.mfaCode.$error ? validationMessages.mfaCode : ''"
        >
            <b-input
                v-model="mfaCode"
                placeholder="MFA Code (6 digits)"
                type="tel"
                maxlength="6"
                inputmode="numeric"
            />
        </b-field>

        <div v-if="apiError" class="my-1 has-text-centered has-text-danger">
            <small>{{ apiError }}</small>
        </div>

        <div class="home-form__footer">
            <div class="home-form__cta mt-5 has-text-centered">
                <button
                    class="button be-button is-wide is-primary is-rounded"
                    type="submit"
                    :class="{ 'is-loading': isLoading }"
                    :disabled="isLoading"
                >
                    <span v-if="!isLoading">Submit</span>
                    <span v-else>Submitting&hellip;</span>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import { getApiUrl } from '@/utils/api';

export default {
    mixins: [validationMixin],

    validations: {
        mfaCode: {
            required,
        },
    },

    data() {
        return {
            isLoading: false,
            mfaCode: '',
            mfaQRCode: '',
            requestToken: '',
            apiError: null,
            errors: {},
            validationMessages: {
                mfaCode: 'Please enter your MFA code',
            },
        };
    },

    mounted() {
        this.loadMfaQRCode();
    },

    methods: {

        async loadMfaQRCode() {
            try {
                const response = await axios({
                    method: 'GET',
                    url: getApiUrl({ path: 'auth/mfa/secret' }),
                });

                this.mfaQRCode = response.data.qrcode;
                this.requestToken = response.data.request_token;
            } catch (error) {
                this.apiError = error.response.data?.detail ?? error.response.data[0];
            }
        },

        async submit() {
            if (this.isLoading) {
                return false;
            }
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            }
            try {
                this.isLoading = true;
                const response = await axios({
                    method: 'POST',
                    url: getApiUrl({ path: 'auth/mfa' }),
                    data: {
                        code: this.mfaCode,
                        request_token: this.requestToken,
                    },
                });
                this.isLoading = false;

                if (response.status === 201) {
                    this.$emit('mfa-success', response);
                }
            } catch (error) {
                this.$emit('mfa-error', error.response);
                this.isLoading = false;
                this.apiError = error.response.data?.code[0] ?? 'error';
            }

            return null;
        },

    },
};
</script>
