import { email, maxLength, minLength, required, integer } from 'vuelidate/lib/validators';
import { SITE_NAME_MAXLENGTH, SITE_TYPES } from '@/utils/sites/constants';

export const getDefaultSiteState = (item = null, siteType = null) => ({
    site_type: item ? item.siteType : siteType,
    name: item ? item.name : '',
    address: item ? item.address : '',
    lat: item ? item.lat : '',
    lng: item ? item.lng : '',
    number_of_bays: item ? item.number_of_bays : 0,
    occupancy_count: item?.occupancy_count || 0,
    safety_manager: {
        email: [],
        name: '',
        phone: [],
        ...(item ? item.safety_manager : {}),
    },
    stms: {
        email: [],
        name: '',
        phone: [],
        ...(item ? item.stms : {}),
    },
});

export const getSiteValidationRules = (siteType) => {
    const baseRules = {
        name: {
            required,
            maxLength: maxLength(SITE_NAME_MAXLENGTH),
        },
        address: { required },
        lat: { required },
        lng: { required },
    };

    const siteTypeRules = {
        [SITE_TYPES.HS]: {
            safety_manager: {
                email: {
                    required,
                    minLength: minLength(1),
                    $each: { required, email },
                },
                name: { required },
                phone: {
                    required,
                    $each: { required },
                },
            },
            stms: {
                email: {
                    required,
                    minLength: minLength(1),
                    $each: { required, email },
                },
                name: { required },
                phone: {
                    required,
                    $each: { required },
                },
            },
        },
        [SITE_TYPES.CARPARK]: {
            number_of_bays: { required, integer },
            occupancy_count: { required, integer },
        },
    };

    return {
        ...baseRules,
        ...(siteTypeRules[siteType] || {}),
    };
};

export const SITE_VALIDATION_MESSAGES = {
    name: {
        required: 'Site name is required',
        maxLength: `Site name must be less than ${SITE_NAME_MAXLENGTH}
                    characters in length. Please choose a shorter name.`,
    },
    address: 'Site address is required',
    lat: 'Site latitude is required',
    lng: 'Site longitude is required',
    number_of_bays: 'Number of bays is required',
    occupancy_count: 'Occupancy count is required',
    safety_manager: {
        email: 'Safety manager email is required',
        name: 'Safety manager name is required',
        phone: 'Safety manager phone is required',
    },
    stms: {
        email: 'STMS email is required',
        name: 'STMS name is required',
        phone: 'STMS phone is required',
    },
};
