<template>
    <div class="dashboard" :class="'has-nav-closed'">
        <div class="dashboard__container">
            <div class="dashboard__app">
                <div class="dashboard__app__content">
                    <div class="fields-single">
                        <div class="app-container" v-if="form && !isError">
                            <b-loading
                                :active="isLoading"
                                :is-full-page="false"
                                :can-cancel="false"
                            />
                            <be-page-header :app-container="false">
                                <template v-slot:default>
                                    User Registration
                                </template>
                                <template v-slot:back>
                                    <b-button
                                        tag="router-link"
                                        type="is-light"
                                        to="/dashboard/user-management"
                                        class="be-button is-back"
                                        icon-left="chevron-left"
                                    />
                                </template>
                            </be-page-header>
                            <div class="fields-single__form">
                                <div class="columns is-multiline">
                                    <div class="column is-6">
                                        <b-field
                                            label="First Name"
                                            :type="fieldType('firstName')"
                                            :message="fieldMessage('firstName')"
                                        >
                                            <b-input
                                                v-model="form.firstName"
                                            />
                                        </b-field>
                                    </div>
                                    <div class="column is-6">
                                        <b-field
                                            label="Last Name"
                                            :type="fieldType('lastName')"
                                            :message="fieldMessage('lastName')"
                                        >
                                            <b-input
                                                v-model="form.lastName"
                                            />
                                        </b-field>
                                    </div>
                                    <div class="column is-12">
                                        <b-field
                                            label="Email"
                                            :type="fieldType('email')"
                                            :message="fieldMessage('email')"
                                        >
                                            <b-input
                                                v-model="form.email"
                                                readonly="isReadOnly"
                                            />
                                        </b-field>
                                    </div>
                                    <div class="column is-12">
                                        <b-field
                                            label="Password"
                                            :type="fieldType('password')"
                                            :message="fieldMessage('password')"
                                        >
                                            <b-input
                                                type="password"
                                                v-model="form.password"
                                            />
                                        </b-field>
                                    </div>
                                    <div class="column is-12">
                                        <b-field
                                            label="Confirm Password"
                                            :type="fieldType('confirmPassword')"
                                            :message="fieldMessage('confirmPassword')"
                                        >
                                            <b-input
                                                type="password"
                                                v-model="form.confirmPassword"
                                            />
                                        </b-field>
                                    </div>
                                </div>
                                <div class="column is-12" style="color: #FD9D90;">
                                    <span v-html="textError" />
                                </div>
                                <hr />
                                <div class="columns fields-single__actions">
                                    <div class="column is-6">
                                        <b-button
                                            class="be-button is-primary"
                                            expanded
                                            @click="save"
                                            :loading="isSaving"
                                        >
                                            Create
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="isError" class="app-container">
                            <div
                                class="py-5 content has-text-grey-lighter has-text-centered"
                            >
                                <p>Invalid Form</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, required, sameAs } from 'vuelidate/lib/validators';
import axios from 'axios';
import noticesMixin from '@/mixins/noticesMixin';
import { getApiUrl } from '@/utils/api';

const getDefaultValues = () => ({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
});

export default {
    mixins: [
        validationMixin,
        noticesMixin,
    ],

    mounted() {
        this.validateToken();
        this.isReady = true;
    },

    data() {
        return {
            form: getDefaultValues(),
            isReady: false,
            isSaving: false,
            isRemoving: false,
            isError: false,
            responseError: null,

            validationMessages: {
                action: 'Please select an form action',
                firstName: 'Please enter first name',
                lastName: 'Please enter last name',
                role: 'Please select a role',
                email: 'Please enter a (valid) email',
                password: 'Please enter a password',
                confirmPassword: "Password doesn't match",
            },
            infoMessages: {
            },
        };
    },

    validations() {
        const form = {
            firstName: { required },
            lastName: { required },
            email: { required, email },
            password: { required },
            confirmPassword: { required, sameAsPassword: sameAs('password') },
        };

        return { form };
    },

    computed: {
        token() {
            return this.$route.params.token;
        },

        isLoading() {
            return !this.isReady || this.isSaving || this.isRemoving || this.isError;
        },

        canAction() {
            return !this.isSaving && !this.isRemoving;
        },

        textError() {
            if (this.responseError === null || this.responseError.password1 === undefined) {
                return '';
            }

            return this.responseError.password1.join('<br>');
        },
    },

    methods: {

        validateToken() {
            const promise = axios({
                method: 'get',
                url: getApiUrl({ path: `organisation/invites/verify/${this.token}` }),
            });

            promise.then((result) => {
                this.isError = false;
                this.form.email = result.data.email;
            }).catch((e) => {
                this.isError = true;
                console.log(e);
                this.displayErrorNotice({
                    message: 'Invalid invite',
                });
            });
        },

        getSaveData() {
            const data = {
                first_name: this.form.firstName,
                last_name: this.form.lastName,
                email: this.form.email,
                password1: this.form.password,
                password2: this.form.confirmPassword,
            };

            return data;
        },

        async save() {
            if (!this.canAction) {
                return;
            }

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isSaving = true;
            this.closeNotices();

            const promise = this.createUser(this.getSaveData());

            promise.then(() => {
                this.responseError = null;
                this.displaySuccessNotice({
                    message: this.isNew
                        ? 'User added successfully'
                        : 'User updated successfully',
                    closeOthers: true,
                });
                this.$router.push('/login');
            }).catch((e) => {
                this.responseError = e.response.data;
                this.displayErrorNotice({
                    message: this.isNew
                        ? 'Cannot save form'
                        : 'Cannot update form',
                });
            }).finally(() => {
                this.isSaving = false;
            });
        },

        createUser(payload) {
            return axios({
                method: 'post',
                url: getApiUrl({ path: 'auth/registration/' }),
                data: payload,
            });
        },

        fieldType(field) {
            return this.$v.form[field].$error ? 'is-danger' : '';
        },

        fieldMessage(field) {
            if (this.$v.form[field].$error && this.validationMessages[field]) {
                return this.validationMessages[field];
            }

            return this.infoMessages[field]
                ? this.infoMessages[field]
                : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.fields-single {
    .app-container {
        position: relative;
    }

    &__form {
        width: 100%;
        max-width: 500px;
    }

    &__actions {
        align-items: center;
        justify-content: end;

        ::v-deep .b-checkbox {
            display: flex;
        }
    }

    ::v-deep .field {
        &.form-list.is-grouped {
            @include mobile {
                display: block;
                .field {
                    margin-right: 0;
                    margin-bottom: .5rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            @include tablet {
                .field.has-checkbox {
                    width: 190px;
                    flex: 0 0 190px;
                }
            }
        }

        &.has-checkbox {
            display: flex;
            align-items: center;

            .checkbox {
                margin-right: 0;
            }
        }
    }

    ::v-deep .tooltip-trigger {
        line-height: 1;

        > .icon {
            margin-top: 8px;
        }
    }
}

</style>
