<template>
    <div class="account">
        <div class="app-container">
            <be-page-header :app-container="false">
                Account
            </be-page-header>
            <div class="columns is-multiline">
                <div class="column is-12">
                    <div class="card is-rounded">
                        <div class="card-header">
                            <div class="card-header-title">
                                Your Profile
                            </div>
                        </div>
                        <div class="card-content">
                            <p><strong>{{ fullName }}</strong></p>
                            <p>{{ userDetails.email }}</p>
                        </div>
                        <div class="card-footer">
                            <div class="card-footer-item">
                                <a
                                    @click="showPasswordPanel = true"
                                    @keydown.enter="showPasswordPanel = true"
                                    tabindex="0"
                                >
                                    Change Password
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-12">
                    <div class="card is-rounded">
                        <div class="card-header">
                            <div class="card-header-title">
                                Multi-Factor Authentication (MFA)
                            </div>
                        </div>
                        <div class="card-content">
                            <p><strong>Status: </strong><span :class="{ 'has-text-success': mfaStatus, 'has-text-danger': !mfaStatus }">{{ mfaStatus ? 'Enabled' : 'Disabled' }}</span></p>
                            <p v-if="mfaStatus"><strong>Enabled At: </strong>{{ new Date(mfaCreatedDate).toLocaleString() }}</p>
                        </div>
                        <div class="card-footer">
                            <div class="card-footer-item">
                                <a
                                    @click="showMfaSetup = true"
                                    @keydown.enter="showMfaSetup = true"
                                    tabindex="0"
                                >
                                    {{ mfaStatus ? 'Deactivate MFA' : 'Activate MFA' }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <password-change-dialog
            :active="showPasswordPanel"
            @close="showPasswordPanel = false"
        />
        <b-modal
            :active="showMfaSetup"
            has-modal-card
            trap-focus
            :can-cancel="false"
            @close="showMfaSetup = false">
            <div class="modal-card is-narrow">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {{ mfaStatus ? 'Deactivate MFA' : 'Activate MFA' }}
                    </p>
                    <button type="button" class="modal-card-close" @click.prevent="showMfaSetup = false">
                        <b-icon icon="close" />
                    </button>
                </header>
                <section class="modal-card-body">
                    <mfa-input @mfa-success="handleMfaSuccess" :action="'deactivate'" v-if="mfaStatus" />
                    <mfa-setup @mfa-success="handleMfaSuccess" v-else />
                </section>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import PasswordChangeDialog from '@/components/widgets/PasswordChangeDialog';
import MfaSetup from '@/components/widgets/MfaSetup';
import MfaInput from '@/components/widgets/MfaInput';
import { getApiUrl } from '@/utils/api';

export default {
    // TODO: better separation of concerns, break out payment & plan logic
    name: 'Account',
    components: {
        PasswordChangeDialog,
        MfaSetup,
        MfaInput,
    },
    data: () => ({
        showPasswordPanel: false,
        showMfaSetup: false,
        mfaStatus: false,
        mfaCreatedDate: null,
    }),
    computed: {
        ...mapGetters('user', ['userDetails', 'userStatus']),
        ...mapGetters('payment', ['cardDetails', 'cardStatus']),
        ...mapGetters('plans', ['allPlans']),
        fullName() {
            const first_name = this.userDetails.first_name || ''; // eslint-disable-line camelcase
            const last_name = this.userDetails.last_name || ''; // eslint-disable-line camelcase
            return `${first_name} ${last_name}`; // eslint-disable-line camelcase
        },
        userLoading() {
            return this.userStatus === 'loading';
        },
    },
    mounted() {
        this.loadMFAStatus();
    },
    methods: {
        async loadMFAStatus() {
            try {
                const response = await axios({
                    method: 'GET',
                    url: getApiUrl({ path: 'auth/mfa' }),
                });

                this.mfaStatus = response.data.status === 'enabled';
                this.mfaCreatedDate = response.data?.created_at ?? null;
            } catch (error) {
                this.apiError = error.response.data?.detail ?? error.response.data[0];
            }
        },

        handleMfaSuccess() {
            this.showMfaSetup = false;
            this.loadMFAStatus();
        },
    },
};
</script>
