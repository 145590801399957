<template>
    <be-widget className="chart">
        <template v-slot:heading>
            {{ widgetTitle }}
        </template>
        <template v-slot:content>
            <div className="chart">
                <line-chart
                    className="chart__wrapper"
                    :chart-data="lineChartData"
                    :options="barChartOptions"
                />
            </div>
        </template>
    </be-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import {
    DATE_FORMAT,
    DATE_SIMPLE_FORMATTED_FORMAT,
    DATETIME_FORMAT_SEC,
    DATETIME_SIMPLE_FORMATTED_FORMAT,
} from '@/utils/formatters/datetime';
import LineChart from '@/utils/chartjs/LineChart';
import { COLORS } from '@/utils/constants';

const getChartDataDefaults = () => ({ labels: [], datasets: [] });

export default {
    components: {
        LineChart,
    },

    data() {
        return {
            lineChartData: getChartDataDefaults(),
            barChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    position: 'top',
                    labels: {
                        boxWidth: 10,
                        padding: 10,
                        usePointStyle: true,
                        fontColor: COLORS.white,
                    },
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            major: {
                                enabled: true,
                            },
                            fontColor: COLORS.white,
                        },
                        type: 'time',
                        time: {
                            tooltipFormat: 'YYYY MMMM DD hA',
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Time',
                        },
                        bounds: 'ticks',
                    }],
                    yAxes: [
                        {
                            ticks: {
                                suggestedMin: 0,
                                stepSize: 10,
                                fontColor: COLORS.white,
                            },
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            scaleLabel: {
                                display: true,
                                // labelString: 'Risk level',
                                labelString: 'Number of events',
                            },
                        },
                    ],
                },
                plugins: {
                    tooltip: {
                        enabled: false,
                    },
                },
            },
        };
    },

    computed: {
        ...mapGetters('hs/observations/observationFilters', ['getDateRange', 'selectedSites']),
        ...mapGetters('hs/observations/summary', [
            'isLoading',
            'getData',
            'getAppliedFilters',
        ]),

        sites() {
            const colors = Object.values(COLORS);
            return this.selectedSites.map(
                (site, index) => ({
                    ...site,
                    color: colors[index],
                }),
            );
        },

        widgetTitle() {
            // return 'Risk level for period';
            return 'All events over period';
        },

        apiResults() {
            return this.sites.map((site) => this.generateSiteResults(site));
        },

        displayDates() {
            const startDate = this.$date(this.getDateRange.from);
            const endDate = this.$date(this.getDateRange.to);

            const dateArray = [];
            const diff = Math.floor(endDate.diff(startDate, 'day', true));
            if (diff < 7) {
                const diffHour = Math.floor(endDate.diff(startDate, 'hour', true));
                for (let i = 0; i < diffHour; i++) {
                    const datetime = startDate.add(i + 1, 'hour');
                    dateArray.push({
                        date: datetime.format(DATETIME_FORMAT_SEC),
                        label: datetime.format(DATETIME_SIMPLE_FORMATTED_FORMAT),
                    });
                }
            } else {
                for (let i = 0; i < diff; i++) {
                    const date = startDate.add(i + 1, 'day');
                    dateArray.push({
                        date: date.format(DATE_FORMAT),
                        label: date.format(DATE_SIMPLE_FORMATTED_FORMAT),
                    });
                }
            }

            return dateArray;
        },
    },

    watch: {
        getData: {
            handler: 'updateChartData',
            immediate: true,
            deep: true,
        },
    },

    methods: {

        generateSiteResults(site) {
            const data = this.getData;
            let violationData = data?.map((x) => x.data).flat();

            const results = {
                label: 'All Events',
                borderColor: site.color,
                backgroundColor: site.color,
                fill: false,
                tension: 0.4,
                cubicInterpolationMode: 'monotone',
                data:
                    this.displayDates?.map((e) => {
                        let count = 0;
                        if (violationData === undefined) {
                            return [];
                        }
                        const eDate = dayjs(e.date);
                        violationData = violationData?.filter((row) => {
                            const rowDate = dayjs(row.violation_hour);
                            if (rowDate.isSame(eDate, 'hour')) {
                                count += row.violation_count;
                                return false;
                            }
                            return true;
                        }) || [];

                        return count;
                    }),
            };

            return results;
        },

        canPopulateChart() {
            return (
                this.apiResults
                && this.getDateRange.from
                && this.getDateRange.to
            );
        },

        getChartData() {
            if (!this.apiResults) {
                return {};
            }

            return {
                labels: this.displayDates.map((d) => d.label),
                datasets: this.apiResults,
            };
        },

        updateChartData() {
            // to overcome the problem of non-reactive deep updates
            this.resetChartData();
            if (!this.canPopulateChart()) {
                return;
            }
            this.$nextTick(() => {
                this.lineChartData = this.getChartData();
            });
        },

        resetChartData() {
            this.lineChartData = getChartDataDefaults();
        },
    },
};
</script>

<style lang="scss" scoped>
.chart {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__wrapper {
        position: relative;
        height: 500px;
        width: 100%;
        margin-top: auto;
    }
}
</style>
