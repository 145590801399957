<template>
    <div class="buttons">
        <b-button
            v-for="level in riskLevels"
            :key="`levels${level.level}`"
            class="be-button is-wider"
            :class="{
                'is-primary': computedValue === level.level,
            }"
            @click="select(level.level)"
        >
            <span class="level-label">
                <span
                    v-if="level.level !== 'all'"
                    class="level-dot"
                    :class="`is-${level.level}`"
                />
                <span>{{ level.label }}</span>
            </span>
        </b-button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import modelMixin from '@/mixins/modelMixin';

export default {
    mixins: [modelMixin],

    props: {
        value: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapState('dataFilters', ['riskLevels']),
    },

    methods: {
        select(level) {
            this.computedValue = level;
        },
    },
};
</script>

<style lang="scss" scoped>
.level-label {
    display: inline-flex;
    align-items: center;
}

.level-dot {
    width: 9px;
    height: 9px;
    border-radius: 9px;
    display: inline-flex;
    margin-right: 7px;

    &.is-low {
        background: $success;
    }

    &.is-medium {
        background: $warning;
    }

    &.is-high {
        background: $danger;
    }
}
</style>
