var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-video"},[_c('b-loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),(_vm.url)?[(_vm.isError)?[_c('div',{staticClass:"media-video__error"},[_c('div',{staticClass:"content"},[_c('p',[_vm._v(_vm._s(_vm.message))])])])]:[_c('div',{ref:"container",staticClass:"media-video__container"},[_c('div',{ref:"videoContainer",staticClass:"media-video__video-container"},[_c('div',{ref:"scene",staticClass:"media-video__scene",style:(_vm.sceneStyles)},[_c('div',{staticClass:"media-video__wrapper"},[(_vm.isPlaying)?_c('video',{ref:("video-" + _vm.url),attrs:{"src":_vm.url,"poster":_vm.poster,"controls":"","autoplay":"","loop":false},on:{"error":_vm.onVideoError,"play":_vm.onVideoPlay,"loadeddata":_vm.onVideoLoad}}):_c('div',{staticClass:"media-video__preview",on:{"click":function($event){return _vm.play()}}},[(_vm.poster)?_c('img',{ref:"image",staticClass:"media-video__preview-image",attrs:{"src":_vm.poster,"alt":""},on:{"load":_vm.onImageLoad}}):_c('span',{staticClass:"media-video__preview-bg"}),_c('button',{staticClass:"media-video__preview-cta",attrs:{"type":"button"}},[_c('b-icon',{attrs:{"icon":"play"}})],1)]),(_vm.isHS)?_c('media-image-h-s-canvas',{staticClass:"media-video__canvas",style:({
                                    width: ((this.imageSize.width) + "px"),
                                    height: ((this.imageSize.height) + "px"),
                                }),attrs:{"is-visible":!_vm.isLoading,"width":_vm.imageSize.width,"height":_vm.imageSize.height,"detections":_vm.detections,"camera":_vm.camera,"scale":_vm.scale,"translate":_vm.translate,"displayRoi":_vm.displayRoi,"displayHeatmap":_vm.displayHeatmap,"heatmapImage":_vm.heatmapImage,"imageConfig":_vm.imageConfig}}):_vm._e(),(!_vm.isHS)?_c('media-image-canvas',{staticClass:"media-video__canvas",attrs:{"is-visible":!_vm.isLoading,"width":_vm.imageSize.width,"height":_vm.imageSize.height,"detections":_vm.detections,"image-type":_vm.imageType,"scale":_vm.scale,"translate":_vm.translate}}):_vm._e()],1)])]),_c('div',{staticClass:"media-video__timeline",style:({
                        height: ((this.imageSize.height) + "px"),
                        width: "15%",
                    })},[(_vm.timestamps.length > 0)?_c('ul',[_c('div',{staticClass:"media-video__timeline_title"},[_vm._v("List of observation times:")]),_vm._l((_vm.timestamps),function(timestamp){return _c('li',{key:timestamp.frame_number,on:{"click":function($event){return _vm.seekToTimestamp(timestamp.start)}}},[_c('span',{staticClass:"media-video__timeline_bullet"},[_vm._v("•")]),_c('span',{staticClass:"media-video__timeline_timestamp"},[_vm._v(_vm._s(((_vm.formatTime(timestamp.start)) + "-" + (_vm.formatTime(timestamp.end)))))]),_c('span',{staticClass:"media-video__timeline_violation"},[_vm._v(_vm._s((" " + (timestamp.violationText))))]),_c('span',{style:({
                                    color: timestamp.riskLevel >= 0 && timestamp.riskLevel < 2
                                        ? 'green'
                                        : timestamp.riskLevel >= 2 && timestamp.riskLevel < 4
                                            ? 'orange'
                                            : 'red',
                                })},[_vm._v(" "+_vm._s((" [" + (timestamp.riskLevel) + "]"))+" ")])])})],2):(_vm.isPlaying && _vm.timestamps.length === 0)?_c('ul',[_c('li',[_vm._v("No detections found")])]):_c('ul',[_c('li',[_vm._v("Play video to see observation timestamps")])])])])]]:[_c('div',{staticClass:"media-video__error"},[_c('div',{staticClass:"content"},[_c('p',[_vm._v(_vm._s(_vm.message))])])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }