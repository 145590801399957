import axios from 'axios';
import dayjs from 'dayjs';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { cancelAllRequests } from '@/utils/api-tokens';
import { getVuexStorage, STORAGE_EXPIRATION, VUEX_STORAGE_KEY } from '@/utils/vuex';

const getDefaultState = () => ({
    token: '',
    status: '',
    expiration: null,
});

const state = getDefaultState();

const mutations = {
    setAuthStatus(state, payload) {
        state.status = payload.status;
    },
    setAuthToken(state, payload) {
        state.token = payload.token;
        state.expiration = payload.expiration;
        if (state.token !== '') {
            axios.defaults.headers.common.Authorization = `Bearer ${state.token}`;
        } else {
            axios.defaults.headers.common.Authorization = '';
        }
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const getters = {
    isLoggedIn: (state, getters) => !!state.token && !getters.isExpired,
    isExpired: (state) => state.expiration && state.expiration < dayjs().unix(),
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,
};

const actions = {
    userLoginOrRegister({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAuthStatus', { status: STATUS_LOADING });
            axios({
                url: getApiUrl({
                    path: payload.type === 'register' ? 'auth/registration' : 'auth/login',
                }),
                data: payload.auth,
                method: 'POST',
            })
                .then((response) => {
                    if (payload.type === 'login') {
                        const token = response.data.key;
                        const expiration = dayjs().add(STORAGE_EXPIRATION, 'second').unix();
                        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                        commit('setAuthToken', { token, expiration });
                        dispatch('user/getUserDetails', null, { root: true })
                            .catch((err) => {
                                errorHandler('there was an error with the user API', err);
                            });
                    }

                    return dispatch('afterLogin')
                        .then(() => {
                            commit('setAuthStatus', { status: STATUS_SUCCESS });
                            resolve(response);
                        });
                })
                .catch((err) => {
                    commit('setAuthStatus', { status: STATUS_ERROR });
                    commit('setAuthToken', { token: '', expiration: null });
                    reject(err);
                });
        });
    },

    logout({ commit, dispatch }) {
        axios.post(getApiUrl({ path: 'auth/logout' }));

        const resetParams = { root: true };

        const resetModules = [
            'plans',
            'summary',
            'sites',
            'webhooks',
            'dataFilters',
            'explorer',
            'dashboard',
            'user',
            'payment',
            'liveExplorer',
            'stolenPlates',
            'media',
            'nav',
        ];

        const resetActions = resetModules.map((module) => (
            dispatch(`${module}/resetState`, null, resetParams)
        ));

        return Promise.all(resetActions)
            .finally(() => {
                commit('resetState');
                cancelAllRequests({ refresh: true });
                getVuexStorage().removeItem(VUEX_STORAGE_KEY);
                delete axios.defaults.headers.common.Authorization;
            });
    },

    afterLogin() {
        return Promise.all([]);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
