<template>
    <form @submit.prevent="submit" novalidate>
        <p class="subtitle is-6 has-text-centered mt-3">
            Enter the code from your authenticator app
        </p>

        <b-field
            :type="$v.mfaCode.$error ? 'is-danger' : ''"
            :message="$v.mfaCode.$error ? validationMessages.mfaCode : ''"
        >
            <b-input
                v-model="mfaCode"
                placeholder="MFA Code (6 digits)"
                type="tel"
                maxlength="6"
                inputmode="numeric"
            />
        </b-field>

        <div v-if="apiError" class="my-1 has-text-centered has-text-danger">
            <small>{{ apiError }}</small>
        </div>

        <div class="home-form__footer">
            <div class="home-form__cta mt-5 has-text-centered">
                <button
                    class="button be-button is-wide is-primary is-rounded"
                    type="submit"
                    :class="{ 'is-loading': isLoading }"
                    :disabled="isLoading"
                >
                    <span v-if="!isLoading">Submit</span>
                    <span v-else>Submitting&hellip;</span>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import dayjs from 'dayjs';
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import { mapMutations } from 'vuex';
import { STORAGE_EXPIRATION } from '@/utils/vuex';
import { getApiUrl } from '@/utils/api';

export default {
    mixins: [validationMixin],

    validations: {
        mfaCode: {
            required,
        },
    },

    props: {
        action: {
            type: String,
            default: 'authenticate', // 'authenticate' or 'deactivate'
        },
    },

    data() {
        return {
            isLoading: false,
            mfaCode: '',
            apiError: null,
            errors: {},
            validationMessages: {
                mfaCode: 'Please enter your MFA code',
            },
        };
    },

    methods: {
        ...mapMutations('auth', ['setAuthToken']),

        async submit() {
            if (this.isLoading) {
                return false;
            }
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            }

            this.isLoading = true;

            const isAuthenticate = this.action === 'authenticate';
            const method = isAuthenticate ? 'PUT' : 'DELETE';
            const url = getApiUrl({ path: isAuthenticate ? 'auth/mfa/authenticate' : 'auth/mfa' });
            const successCode = 200;

            try {
                const response = await axios({
                    method,
                    url,
                    data: {
                        code: this.mfaCode,
                    },
                });

                this.isLoading = false;

                if (response.status === successCode) {
                    const expiration = dayjs().add(STORAGE_EXPIRATION, 'second').unix();

                    this.setAuthToken({ token: response.data.key, expiration });
                    this.$emit('mfa-success', response);
                }
            } catch (error) {
                this.isLoading = false;
                this.apiError = error.response.data?.code[0] ?? 'error';
                this.$emit('mfa-error', error.response);
            }

            return true;
        },
    },
};
</script>
