import axios from 'axios';
import dayjs from 'dayjs';
import {
    buildStartEndDateUrl, buildUrlParams, createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { getApiUrl } from '@/utils/api';

const state = {
    ...getDefaultDataState({ hasPagination: false }),
    countData: [],
};

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),

    getCountData(state) {
        return state.countData;
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),

    setCountData(state, payload) {
        state.countData = payload;
    },
};

const actions = {
    async loadData({
        state, commit, rootState, rootGetters,
    }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            confidence: rootGetters['dataFilters/getConfidenceLevel'],
            risk: rootGetters['dataFilters/getRiskLevel'],
        });

        const path = buildStartEndDateUrl({
            path: 'hsafety/detections/summary',
            startDate,
            endDate,
        });

        const dataHash = createDataHash(path, params);

        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setDataHash', { dataHash });
        commit('setCountData', []);
        commit('setStatus', { status: 'loading' });

        try {
            const riskTypes = ['ExclusionZone', 'OverSpeed-GroupB', 'OverSpeed-GroupC', 'DistanceZone-Worker'];

            const fetchAndPushData = async (riskType) => {
                try {
                    const response = await axios(
                        getApiUrl({ path: 'hsafety/observations/count' }),
                        {
                            params: {
                                risk_filter_type: riskType,
                                observation_filter_from: dayjs(startDate).format('YYYY-MM-DDTHH:mm:00'),
                                observation_filter_to: dayjs(endDate).format('YYYY-MM-DDTHH:mm:00'),
                            },
                        },
                    );

                    const currentData = state.countData ?? [];
                    commit('setCountData', [...currentData, { data: response.data, riskType }]);
                } catch (error) {
                    console.error(`Error fetching data for ${riskType}:`, error);
                }
            };

            const riskTypeRequests = riskTypes.map((riskType) => fetchAndPushData(riskType));

            await Promise.all(riskTypeRequests);
            commit('setStatus', { status: 'success' });
        } catch (e) {
            console.error('Error loading data:', e);
            commit('setStatus', { status: 'error' });
        }

        return Promise.resolve(true);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
